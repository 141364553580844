const _config = {
  local: {
    login_app: '/fake-login',
    client_id: 'foo',
    scope: 'openid email profile address app_role',
    orgManagement: 'fake/entity-management/registration',
    developerApps: 'fake/user/me/apps',
    developerAppsAddSolution: 'fake/user/me/apps/add',
    developerAppsRegisterSolution: 'fake/user/me/apps/add/register',
    fortellisURL: 'fake/fortellis.io/',
    accountsServerURL: 'fake/fortellis.io',
    accountsURL: 'fake/signup',
    accountsSettingsURL: 'fake/settings',
    docs: 'fake/docs/ ',
    pricing: 'fake/pricing/',
    developerNetworkURL: 'fake/developer-dev/',
    api_reference_URL: 'fake/apidocs/',
    community_URL: 'fake/community/',
    marketplaceURL: 'fake/marketplace-dev/',
    sellURL: 'fake/sell',
    fortellisAdmin: ' ',
    betaAccessEmailSubject: '[dev]New Request for Marketplace Beta access', //to be removed later
    developerAccountUrl:
      'https://ioy33uwhri.execute-api.us-west-2.amazonaws.com/api/v1',
    developerAccountDefaultApi: 'MarketplaceDevTestAPI',
    social_links: {
      facebook: 'https://www.facebook.com/FortellisACE/',
      twitter: 'https://twitter.com/fortellisACE',
      github: 'https://github.com/fortellis',
      youtube: 'https://www.youtube.com/channel/UCk4etySO7-MJhYCPNDtox2A',
      reddit: 'https://www.reddit.com/user/fortellis'
    },
    footer: {
      developer_links: {
        gettingStarted:
          'fake//docs.fortellis.io/docs/general/overview/introduction/',
        APIReference: 'fake//developer.fortellis.io/api-reference',
        vehicleSalesAPIs:
          'fake//developer.fortellis.io/api-reference/vehicle-sales',
        vehicleServiceAPIs:
          'fake://developer.fortellis.io/api-reference/vehicle-service'
      },
      support_links: {
        contactUs: 'fake//developer.fortellis.io/contact-us',
        developerCommunity: 'fake//community.fortellis.io',
        developerDocumentation: 'fake//docs.fortellis.io'
      },
      legal_links: {
        legal: 'fake//fortellis.io/privacy-legal',
        termsOfUse: 'fake//fortellis.io/terms-use'
      }
    },
    consentServiceBaseUrl: 'https://documents-svc-dev.fortellis.io',
    dealerSuiteLogin: 'https://subscription-portal-dev.fortellis.io/dp',
    signing_redirect_url:
      'https://marketplace-dev.fortellis.io/signingPreviewComplete',
    registerYourOrgDocsURLSell:
      'https://docs-dev.fortellis.io/docs/general/organizations/registering-for-organizations/',
    agreementManagementUrl:
      'https://agreement-management-dev.fortellis.io/organization-agreement',
    TestAPI: 'MarketplaceDevTestAPI',
    cdkVisionQueueName: 'FORTELLIS-ENG-TEST',
    cdkUninstallVisionQueueName: 'FORTELLIS-ISV-UNINSTALL',
    appSubscriptionActivationInfoPageLink: 'https://docs-dev.fortellis.io/',
    insights_URL: 'https://fortellis.io/insights',
    publisher_agreement_basic:
      'https://marketplace-dev-developer-agreement.s3.us-west-2.amazonaws.com/FortellisBasicAppPublishingAgreement.pdf',
    publisher_agreement_pro:
      'https://marketplace-dev-developer-agreement.s3.us-west-2.amazonaws.com/FortellisProAppPublishingAgreement.pdf',
    caseManagementURL: 'https://sso-qat.fortellis.io/home/salesforce/0oamcm1by5ninGT2P4x7/46',
    orgnization_service: 'https://organizations-dev.fortellis.io',
    paymentServiceUrl: 'https://payments-dev.fortellis.io'
  },
  dev: {
    env: 'dev',
    login_app: 'https://api-dev.identity.fortellis.io/oauth2/v1/authorize',
    logout_api: 'https://api-dev.identity.fortellis.io/oauth2/v1/logout',
    scope: 'openid email profile address app_role',
    client_id: '0oa4qokpibPph9mnb2p7',
    orgManagement: 'https://developer-dev.fortellis.io/org-management',
    developerApps: 'https://developer-dev.fortellis.io/developer-account',
    developerAppsAddSolution:
      'https://developer-dev.fortellis.io/developer-account/solution',
    developerAppsRegisterSolution:
      'https://developer-dev.fortellis.io/developer-account/solutions/register',
    fortellisURL: 'https://www-dev.fortellis.io/',
    accountsServerURL: 'https://api.accounts-dev.fortellis.io',
    accountsUiURL: 'https://accounts-dev.fortellis.io',
    accountsURL: 'https://accounts-dev.fortellis.io/signup',
    accountsSettingsURL: 'https://accounts-dev.fortellis.io/settings',
    docs: 'https://docs-dev.fortellis.io/',
    registerYourOrgDocsURL:
      'https://docs-dev.fortellis.io/docs/general/organizations/registering-for-organizations/',
    gettingStartedDocsURL:
      'https://docs-dev.fortellis.io/docs/tutorials/solution-overview/getting-started/',
    pricing: 'https://pricing-dev.fortellis.io',
    developerNetworkURL: 'https://developer-dev.fortellis.io/',
    providerServiceURL: 'https://provider-dev.fortellis.io/',
    api_reference_URL: 'https://apidocs-dev.fortellis.io/',
    community_URL: 'https://community-dev.fortellis.io/',
    community_blog_URL: 'https://community-dev.fortellis.io/community/blog',
    marketplaceURL: 'https://marketplace-dev.fortellis.io/',
    adminAccount: 'https://admin.accounts-dev.fortellis.io',
    sellURL: 'https://marketplace-dev.fortellis.io/sell',
    fortellisAdmin: 'CDK.MarketplaceDev@cdk.com',
    betaAccessEmailSubject: '[dev]New Request for Marketplace Beta access',
    developerAccountUrl:
      'https://ioy33uwhri.execute-api.us-west-2.amazonaws.com/api/v1',
    developerAccountDefaultApi: 'MarketplaceDevTestAPI',
    social_links: {
      facebook: 'https://www.facebook.com/FortellisACE/',
      twitter: 'https://twitter.com/fortellisACE',
      github: 'https://github.com/fortellis',
      youtube: 'https://www.youtube.com/channel/UCk4etySO7-MJhYCPNDtox2A',
      reddit: 'https://www.reddit.com/user/fortellis'
    },
    footer: {
      developer_links: {
        gettingStarted:
          'https://docs-dev.fortellis.io/docs/general/overview/introduction/',
        APIReference: 'https://developer-dev.fortellis.io/api-reference',
        vehicleSalesAPIs:
          'https://developer-dev.fortellis.io/api-reference/vehicle-sales',
        vehicleServiceAPIs:
          'https://developer-dev.fortellis.io/api-reference/vehicle-service'
      },
      support_links: {
        contactUs: 'https://developer-dev.fortellis.io/contact-us',
        developerCommunity: 'https://community-dev.fortellis.io',
        developerDocumentation: 'https://docs-dev.fortellis.io'
      },
      legal_links: {
        legal: 'https://www-dev.fortellis.io/privacy-legal',
        termsOfUse: 'https://www-dev.fortellis.io/terms-use'
      }
    },
    consentServiceBaseUrl: 'https://documents-svc-dev.fortellis.io',
    dealerSuiteLogin: 'https://subscription-portal-dev.fortellis.io/dp',
    signing_redirect_url:
      'https://marketplace-dev.fortellis.io/signingPreviewComplete',
    registerYourOrgDocsURLSell:
      'https://docs-dev.fortellis.io/docs/general/organizations/registering-for-organizations',
    agreementManagementUrl:
      'https://agreement-management-dev.fortellis.io/organization-agreement',
    TestAPI: 'MarketplaceDevTestAPI',
    cdkVisionQueueName: 'FORTELLIS-ENG-TEST',
    cdkUninstallVisionQueueName: 'FORTELLIS-ISV-UNINSTALL',
    appSubscriptionActivationInfoPageLink: 'https://docs-dev.fortellis.io/',
    insights_URL: 'https://fortellis.io/insights',
    publisher_agreement_basic:
      'https://marketplace-dev-developer-agreement.s3.us-west-2.amazonaws.com/FortellisBasicAppPublishingAgreement.pdf',
    publisher_agreement_pro:
      'https://marketplace-dev-developer-agreement.s3.us-west-2.amazonaws.com/FortellisProAppPublishingAgreement.pdf',
    caseManagementURL: 'https://sso-qat.fortellis.io/home/salesforce/0oamcm1by5ninGT2P4x7/46',
    orgnization_service: 'https://organizations-dev.fortellis.io',
    paymentServiceUrl: 'https://payments-dev.fortellis.io'
  },
  qat: {
    env: 'qat',
    login_app: 'https://api-qat.identity.fortellis.io/oauth2/v1/authorize',
    logout_api: 'https://api-qat.identity.fortellis.io/oauth2/v1/logout',
    scope: 'openid email profile address app_role',
    client_id: '0oaax0tflndRr7b7d4x6',
    orgManagement: 'https://developer-qat.fortellis.io/org-management',
    developerApps: 'https://developer-qat.fortellis.io/developer-account',
    developerAppsAddSolution:
      'https://developer-qat.fortellis.io/developer-account/solution',
    developerAppsRegisterSolution:
      'https://developer-qat.fortellis.io/developer-account/solutions/register',
    fortellisURL: 'https://www-qat.fortellis.io/',
    accountsServerURL: 'https://api.accounts-qat.fortellis.io',
    accountsUiURL: 'https://accounts-qat.fortellis.io',
    accountsURL: 'https://accounts-qat.fortellis.io/signup',
    accountsSettingsURL: 'https://accounts-qat.fortellis.io/settings',
    docs: 'https://docs-qat.fortellis.io/',
    registerYourOrgDocsURL:
      'https://docs-qat.fortellis.io/docs/general/overview/registering-for-organizations/',
    gettingStartedDocsURL:
      'https://docs-qat.fortellis.io/docs/tutorials/solution-overview/getting-started/',
    pricing: 'https://pricing-qat.fortellis.io',
    developerNetworkURL: 'https://developer-qat.fortellis.io/',
    providerServiceURL: 'https://provider-qat.fortellis.io/',
    api_reference_URL: 'https://apidocs-qat.fortellis.io/',
    community_URL: 'https://community-qat.fortellis.io/',
    community_blog_URL: 'https://community-qat.fortellis.io/community/blog',
    marketplaceURL: 'https://marketplace-qat.fortellis.io/',
    adminAccount: 'https://admin.accounts-qat.fortellis.io',
    sellURL: 'https://marketplace-qat.fortellis.io/sell',
    fortellisAdmin: 'CDK.MarketplaceDev@cdk.com',
    betaAccessEmailSubject: '[qat]New Request for Marketplace Beta access',
    developerAccountUrl:
      'https://ioy33uwhri.execute-api.us-west-2.amazonaws.com/api/v1',
    developerAccountDefaultApi: 'MarketplaceDevTestAPI',
    social_links: {
      facebook: 'https://www.facebook.com/FortellisACE/',
      twitter: 'https://twitter.com/fortellisACE',
      github: 'https://github.com/fortellis',
      youtube: 'https://www.youtube.com/channel/UCk4etySO7-MJhYCPNDtox2A',
      reddit: 'https://www.reddit.com/user/fortellis'
    },
    footer: {
      developer_links: {
        gettingStarted:
          'https://docs-qat.fortellis.io/docs/general/overview/introduction/',
        APIReference: 'https://developer-qat.fortellis.io/api-reference',
        vehicleSalesAPIs:
          'https://developer-qat.fortellis.io/api-reference/vehicle-sales',
        vehicleServiceAPIs:
          'https://developer-qat.fortellis.io/api-reference/vehicle-service'
      },
      support_links: {
        contactUs: 'https://developer-qat.fortellis.io/contact-us',
        developerCommunity: 'https://community-qat.fortellis.io',
        developerDocumentation: 'https://docs-qat.fortellis.io'
      },
      legal_links: {
        legal: 'https://www-qat.fortellis.io/privacy-legal',
        termsOfUse: 'https://www-qat.fortellis.io/terms-use'
      }
    },
    consentServiceBaseUrl: 'https://documents-svc-qat.fortellis.io',
    dealerSuiteLogin: 'https://subscription-portal-qat.fortellis.io/dp',
    signing_redirect_url:
      'https://marketplace-qat.fortellis.io/signingPreviewComplete',
    registerYourOrgDocsURLSell:
      'https://docs-qat.fortellis.io/docs/general/organizations/registering-for-organizations/',
    agreementManagementUrl:
      'https://agreement-management-qat.fortellis.io/organization-agreement',
    TestAPI: 'MarketplaceDevTestAPI',
    cdkVisionQueueName: 'FORTELLIS-ENG-TEST',
    cdkUninstallVisionQueueName: 'FORTELLIS-ISV-UNINSTALL',
    appSubscriptionActivationInfoPageLink: 'https://docs-qat.fortellis.io/',
    insights_URL: 'https://fortellis.io/insights',
    publisher_agreement_basic:
      'https://marketplace-qat-developer-agreement.s3.us-west-2.amazonaws.com/FortellisBasicAppPublishingAgreement.pdf',
    publisher_agreement_pro:
      'https://marketplace-qat-developer-agreement.s3.us-west-2.amazonaws.com/FortellisProAppPublishingAgreement.pdf',
    caseManagementURL: 'https://sso-qat.fortellis.io/home/salesforce/0oamcm1by5ninGT2P4x7/46',
    orgnization_service: 'https://organizations-qat.fortellis.io',
    paymentServiceUrl: 'https://payments-qat.fortellis.io'
  },
  demo: {
    env: 'demo',
    login_app: 'https://api-demo.identity.fortellis.io/oauth2/v1/authorize',
    logout_api: 'https://api-demo.identity.fortellis.io/oauth2/v1/logout',
    scope: 'openid email profile address app_role',
    client_id: '0oaax0tflndRr7b7d4x6',
    orgManagement: 'https://developer-demo.fortellis.io/org-management',
    developerApps: 'https://developer-demo.fortellis.io/developer-account',
    developerAppsAddSolution:
      'https://developer-demo.fortellis.io/developer-account/solution',
    developerAppsRegisterSolution:
      'https://developer-deno.fortellis.io/developer-account/solutions/register',
    fortellisURL: 'https://www-demo.fortellis.io/',
    accountsServerURL: 'https://api.accounts-demo.fortellis.io',
    accountsUiURL: 'https://accounts-demo.fortellis.io',
    accountsURL: 'https://accounts-demo.fortellis.io/signup',
    docs: 'https://docs-demo.fortellis.io/ ',
    registerYourOrgDocsURL:
      'https://docs-demo.fortellis.io/docs/general/overview/registering-for-organizations/',
    gettingStartedDocsURL:
      'https://docs-demo.fortellis.io/docs/tutorials/solution-overview/getting-started/',
    pricing: 'https://pricing-demo.fortellis.io',
    developerNetworkURL: 'https://developer-demo.fortellis.io/',
    providerServiceURL: 'https://provider-demo.fortellis.io/',
    api_reference_URL: 'https://apidocs-demo.fortellis.io/',
    community_URL: 'https://community-demo.fortellis.io/',
    community_blog_URL: 'https://community-demo.fortellis.io/community/blog',
    marketplaceURL: 'https://marketplace-demo.fortellis.io/',
    adminAccount: 'https://admin.accounts-demo.fortellis.io',
    sellURL: 'https://marketplace-demo.fortellis.io/sell',
    fortellisAdmin: 'CDK.MarketplaceDev@cdk.com',
    betaAccessEmailSubject: '[demo]New Request for Marketplace Beta access',
    developerAccountUrl:
      'https://ioy33uwhri.execute-api.us-west-2.amazonaws.com/api/v1',
    developerAccountDefaultApi: 'MarketplaceDevTestAPI',
    social_links: {
      facebook: 'https://www.facebook.com/FortellisACE/',
      twitter: 'https://twitter.com/fortellisACE',
      github: 'https://github.com/fortellis',
      youtube: 'https://www.youtube.com/channel/UCk4etySO7-MJhYCPNDtox2A',
      reddit: 'https://www.reddit.com/user/fortellis'
    },
    footer: {
      developer_links: {
        gettingStarted:
          'https://docs-demo.fortellis.io/docs/general/overview/introduction/',
        APIReference: 'https://developer-demo.fortellis.io/api-reference',
        vehicleSalesAPIs:
          'https://developer-demo.fortellis.io/api-reference/vehicle-sales',
        vehicleServiceAPIs:
          'https://developer-demo.fortellis.io/api-reference/vehicle-service'
      },
      support_links: {
        contactUs: 'https://developer-demo.fortellis.io/contact-us',
        developerCommunity: 'https://community-demo.fortellis.io',
        developerDocumentation: 'https://docs-demo.fortellis.io'
      },
      legal_links: {
        legal: 'https://www-demo.fortellis.io/privacy-legal',
        termsOfUse: 'https://www-demo.fortellis.io/terms-use'
      }
    },
    consentServiceBaseUrl: 'https://documents-svc-demo.fortellis.io',
    dealerSuiteLogin: 'https://subscription-portal-demo.fortellis.io/dp',
    signing_redirect_url:
      'https://marketplace-demo.fortellis.io/signingPreviewComplete',
    registerYourOrgDocsURLSell:
      'https://docs-demo.fortellis.io/docs/general/organizations/registering-for-organizations/',
    agreementManagementUrl:
      'https://agreement-management-demo.fortellis.io/organization-agreement',
    TestAPI: 'MarketplaceDevTestAPI',
    cdkVisionQueueName: 'FORTELLIS-ENG-TEST',
    cdkUninstallVisionQueueName: 'FORTELLIS-ISV-UNINSTALL',
    appSubscriptionActivationInfoPageLink: 'https://docs-demo.fortellis.io/',
    insights_URL: 'https://fortellis.io/insights',
    publisher_agreement_basic:
      'https://marketplace-qat-developer-agreement.s3.us-west-2.amazonaws.com/FortellisBasicAppPublishingAgreement.pdf',
    publisher_agreement_pro:
      'https://marketplace-qat-developer-agreement.s3.us-west-2.amazonaws.com/FortellisProAppPublishingAgreement.pdf',
    caseManagementURL: 'https://sso-qat.fortellis.io/home/salesforce/0oamcm1by5ninGT2P4x7/46',
    orgnization_service: 'https://organizations-demo.fortellis.io',
    paymentServiceUrl: 'https://payments-demo.fortellis.io'
  },
  prod: {
    env: 'prod',
    login_app:
      'https://identity.fortellis.io/oauth2/aus1p1ixy7YL8cMq02p7/v1/authorize',
    logout_api: 'https://identity.fortellis.io/logout',
    scope: 'openid email profile address app_role',
    client_id: '0oa4rgkrelVivvAW72p7',
    orgManagement: 'https://developer.fortellis.io/org-management/',
    developerApps: 'https://developer.fortellis.io/developer-account',
    developerAppsAddSolution:
      'https://developer.fortellis.io/developer-account/solution',
    developerAppsRegisterSolution:
      'https://developer.fortellis.io/developer-account/solutions/register',
    fortellisURL: 'https://fortellis.io/',
    accountsServerURL: 'https://api.accounts.fortellis.io',
    accountsUiURL: 'https://accounts.fortellis.io',
    accountsURL: 'https://accounts.fortellis.io/signup',
    accountsSettingsURL: 'https://accounts.fortellis.io/settings',
    docs: 'https://docs.fortellis.io/',
    registerYourOrgDocsURL:
      'https://docs.fortellis.io/docs/general/organizations/registering-for-organizations/',
    gettingStartedDocsURL:
      'https://docs.fortellis.io/docs/tutorials/solution-overview/getting-started/',
    pricing: 'https://pricing.fortellis.io',
    developerNetworkURL: 'https://developer.fortellis.io/',
    providerServiceURL: 'https://provider.fortellis.io/',
    api_reference_URL: 'https://apidocs.fortellis.io/',
    community_URL: 'https://community.fortellis.io/',
    community_blog_URL: 'https://community.fortellis.io/community/blog',
    marketplaceURL: 'https://marketplace.fortellis.io/',
    adminAccount: 'https://admin.accounts.fortellis.io',
    sellURL: 'https://marketplace.fortellis.io/sell',
    fortellisAdmin: 'fortellis.marketplacecustomeronboarding@cdk.com',
    betaAccessEmailSubject: 'New Request for Marketplace Beta access',
    developerAccountUrl:
      'https://t1ob74ju3f.execute-api.us-west-2.amazonaws.com/api/v1',
    developerAccountDefaultApi: 'MarketplaceTestAPI',
    social_links: {
      facebook: 'https://www.facebook.com/FortellisACE/',
      twitter: 'https://twitter.com/fortellisACE',
      github: 'https://github.com/fortellis',
      youtube: 'https://www.youtube.com/channel/UCk4etySO7-MJhYCPNDtox2A',
      reddit: 'https://www.reddit.com/user/fortellis'
    },
    footer: {
      developer_links: {
        gettingStarted:
          'https://docs.fortellis.io/docs/general/overview/introduction/',
        APIReference: 'https://developer.fortellis.io/api-reference',
        vehicleSalesAPIs:
          'https://developer.fortellis.io/api-reference/vehicle-sales',
        vehicleServiceAPIs:
          'https://developer.fortellis.io/api-reference/vehicle-service'
      },
      support_links: {
        contactUs: 'https://developer.fortellis.io/contact-us',
        developerCommunity: 'https://community.fortellis.io',
        developerDocumentation: 'https://docs.fortellis.io'
      },
      legal_links: {
        legal: 'https://fortellis.io/privacy-legal',
        termsOfUse: 'https://fortellis.io/terms-use'
      }
    },
    consentServiceBaseUrl: 'https://documents-svc.fortellis.io',
    dealerSuiteLogin: 'https://subscription-portal.fortellis.io/dp',
    signing_redirect_url:
      'https://marketplace.fortellis.io/signingPreviewComplete',
    registerYourOrgDocsURLSell:
      'https://docs.fortellis.io/docs/general/organizations/registering-for-organizations/',
    agreementManagementUrl:
      'https://agreement-management.fortellis.io/organization-agreement',
    TestAPI: 'MarketplaceTestAPI',
    cdkVisionQueueName: 'FORTELLIS-ENG-TEST',
    cdkUninstallVisionQueueName: 'FORTELLIS-ISV-UNINSTALL',
    appSubscriptionActivationInfoPageLink: 'https://docs.fortellis.io/',
    insights_URL: 'https://fortellis.io/insights',
    publisher_agreement_basic:
      'https://marketplace-prod-developer-agreement.s3.us-west-2.amazonaws.com/FortellisBasicAppPublishingAgreement.pdf',
    publisher_agreement_pro:
      'https://marketplace-prod-developer-agreement.s3.us-west-2.amazonaws.com/FortellisProAppPublishingAgreement.pdf',
    caseManagementURL: 'https://cdkdataservices.my.site.com/DeveloperCare',
    orgnization_service: 'https://organizations.fortellis.io',
    paymentServiceUrl: 'https://payments.fortellis.io'
  }
};

const environment = () => {
  let appHost = window.location.host.toLowerCase();
  if (appHost.indexOf('localhost') > -1) {
    return _config.dev;
  }
  if (appHost.indexOf('marketplace-dev') > -1) {
    return _config.dev;
  }
  if (appHost.indexOf('marketplace-qat') > -1) {
    return _config.qat;
  }
  if (appHost.indexOf('marketplace-demo') > -1) {
    return _config.demo;
  }
  return _config.prod;
};

export const environmentURLs = environment();
