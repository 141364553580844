import routes from '../../Routes';
import { environmentURLs as urls } from '../environment/CaptureEnvironment';
import { openAha } from '@cdk-prod/fortellis-aha-widget';

const isAhaEnabled =
  process.env.REACT_APP_FEATURE_AHA_WIDGET_ENABLED === 'true';
const isCaseManagementEnabled =
  process.env.REACT_APP_NAV_CASE_MANAGEMENT_ENABLED === 'true';

export default {
  nav: {
    home: {
      text: 'Fortellis',
      url: urls.fortellisURL
    },
    apis: {
      text: 'APIs',
      url: urls.api_reference_URL
    },
    marketplace: {
      text: 'Marketplace',
      url: routes.home.path
    },
    community: {
      text: 'Community',
      url: urls.community_URL
    },
    insights: {
      text: 'Insights',
      url: urls.insights_URL
    },

    docs: {
      text: 'Resources',
      url: urls.docs
    },
    user: {
      text: 'User',
      menu: [
        {
          code: 'org-management',
          text: 'Organization Management',
          url: urls.orgManagement
        },
        {
          code: 'developer-account',
          text: 'Developer Account',
          url: urls.developerApps
        },
        {
          code: 'marketplace-account',
          text: 'Marketplace Account',
          menu: [
            {
              code: 'marketplace-account-solutions',
              text: 'Apps',
              url: '/marketplace-account/mysolutions/list'
            }
            // {
            //   code: 'marketplace-account-subscriptions',
            //   text: 'Subscriptions',
            //   url: '/marketplace-account/mysubscriptions'
            // }
          ]
        },
        {
          code: 'divider-desktop',
          text: 'Divider Desktop'
        },
        {
          code: 'settings',
          text: 'Settings',
          url: urls.accountsSettingsURL
        },
        ...(isAhaEnabled
          ? [
              {
                code: 'submit-ideas',
                text: 'Submit Ideas',
                handleMenuClick: () => {
                  const campaignId =
                    process.env.REACT_APP_PLATFORM_CAMPAIGNID_FORTELLIS;
                  openAha(campaignId, isAhaEnabled);
                }
              }
            ]
          : []),
          ...(isCaseManagementEnabled
            ? [
                {
                    code: 'case-management',
                    text: 'Submit/Review a Case',
                    handleMenuClick: () => {
                        window.open(urls.caseManagementURL,'_blank');
                      }
                }
              ]
            : [])
      ]
    }
  },
  signup: {
    url: urls.accountsURL
  }
};
